html, body {
  height: 100%;
  margin: 0;
  font-family: Arial, Helvetica, sans-serif;
}
#root {
  height: 100%;
}

h4 {
  margin: 0;
}
